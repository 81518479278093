import Colours from "./Colours";

const DarkTheme = {
  ...Colours,
  bg: Colours.darkblack,
  navbarBg: Colours.black,
  footerBg: Colours.black,
  primary: Colours.darkgrey,
  secondary: Colours.white,
  highlight: Colours.lightblue,
  other: Colours.lightblue,
  disabled: Colours.grey,
  pop: Colours.gold,
};

export default DarkTheme;
