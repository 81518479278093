import { useContext, useState } from "react";
import styled from "styled-components";
import ThemeContext from "../contexts/ThemeContext";
import lightIcon from "../images/light.svg";
import nightIcon from "../images/night.svg";
import DarkTheme from "./DarkTheme";
import LightTheme from "./LightTheme";

const SwitchStyles = styled.form`
  font-size: 0.85rem;
  margin: 0 1.5rem;

  display: flex;
  align-items: center;

  .mode-text {
    margin: 0 .5rem;
  }

  p {
    margin: 0.25rem;
    text-align: center;
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 10px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid black;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: -12px;
    bottom: -10px;
    background-color: black;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid black;
  }

  input:checked + .slider {
    border: 2px solid black;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px black;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(36px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .light-theme:before,
  .dark-theme:before {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
  }

  .light-theme:before {
    background-image: url(${nightIcon});
    background-color: white;
    border: 1px solid lightgrey;
  }

  .dark-theme:before {
    background-image: url(${lightIcon});
    background-color: black;
  }
`;

export default function ThemeSelector() {
  const { theme, setTheme } = useContext(ThemeContext);
  const [checked, setChecked] = useState(theme === DarkTheme);

  const handleThemeChange = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked)
    const theme = isChecked ? DarkTheme : LightTheme;
    setTheme(theme);
  }

  const modeClass = theme === LightTheme ? "light-theme" : "dark-theme";

  return (
    <SwitchStyles>
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => handleThemeChange(e)}
          aria-label="Theme selector"
        />
        <span className={`slider round ${modeClass}`} />
      </label>
    </SwitchStyles>
  );
}
