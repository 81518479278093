import styled from "styled-components";
import SocialLinks from "./SocialLinks";

const FooterStyles = styled.section`
  position: absolute;
  bottom: 0;

  width: 100%;
  height: 6rem;
  padding: 2rem 0;

  background-color: var(--footerBg);
  color: var(--grey);

  p {
    width: 100%;
    text-align: center;
    opacity: 0.5;
  }
`;

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <FooterStyles>
      <SocialLinks />
      <p>Copyright © {year} . Peter McCready</p>
    </FooterStyles>
  );
}
