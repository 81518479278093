import styled from "styled-components";

const MainContentStyles = styled.main`
  padding-top: 0;
  padding-bottom: 12rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;

export default function MainContent({ children }) {
  return (
    <MainContentStyles>
      {children}
    </MainContentStyles>
  )
}
