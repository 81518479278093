import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const SocialLinksStyles = styled.section`
  display: flex;
  width: 100%;
  justify-content: center;

  .social-link {
    margin: .25rem;
  }

  .social-link-image {
    &:hover {
      background-color: var(--lightblue);
      transition: all var(--transition-speed);
    }
  }
`;

export default function SocialLinks () {
  return (
    <SocialLinksStyles>
      <a
        href="https://github.com/PMGH"
        target="_blank"
        rel="noopener noreferrer"
        className="social-link"
      >
        <StaticImage
          src="../images/github.webp"
          alt="Github"
          placeholder="blurred"
          layout="fixed"
          width={48}
          height={48}
          style={{ borderRadius: "50%" }}
          className="social-link-image"
        />
      </a>
      <a
        href="https://www.linkedin.com/in/peter-mccready/"
        target="_blank"
        rel="noopener noreferrer"
        className="social-link"
      >
        <StaticImage
          src="../images/linkedin.png"
          alt="LinkedIn"
          placeholder="blurred"
          layout="fixed"
          width={48}
          height={48}
          style={{ borderRadius: "50%" }}
          className="social-link-image"
        />
      </a>
    </SocialLinksStyles>
  );
}
