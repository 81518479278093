import { Link } from "gatsby";
import styled from "styled-components";
import ThemeSelector from "./ThemeSelector";

const NavbarStyles = styled.nav`
  background-color: var(--navbarBg);
  color: var(--highlight);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    flex: 1;
    list-style: none;
    margin: 0;
  }
  li {
    margin: 1rem 0rem;
  }
  a {
    padding: 0.75rem;
    font-size: 1.1rem;
    text-decoration: none;
    &:link,
    &:visited {
      color: var(--highlight);
    }
    &:hover {
      opacity: 0.6;
      transition: all var(--transition-speed);
    }
  }

  .active-style,
  .active-style:hover {
    text-decoration: underline;
    text-decoration-color: lightgrey;
    opacity: 1;
  }
`;

export default function Navbar() {
  return (
    <NavbarStyles>
      <ThemeSelector />
      <ul>
        <li>
          <Link to="/" activeClassName="active-style">
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/projects"
            activeClassName="active-style"
            partiallyActive={true}
          >
            Projects
          </Link>
        </li>
        <li>
          <Link
            to="/blogs"
            activeClassName="active-style"
            partiallyActive={true}
          >
            Blogs
          </Link>
        </li>
      </ul>
    </NavbarStyles>
  );
}
